import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import styles from "./main.module.scss";
import { ReactComponent as Divider } from "../../resources/images/divider.svg";
import { ReactComponent as Seal } from "../../resources/images/icca-seal.svg";

class Crest extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <Header />
        <div className="small-12 py-6">
          <h1 className="text-3xl text-center text-gray-dark mt-4 mb-8">
            ICCA Crest
          </h1>
          <Divider className="w-3/4 mx-auto mb-8" />
          <div className="row">
            <div className="small-3 border-r border-black">
              <Seal className="mx-auto" width="250" height="250" />
              <p className="text-center text-xs">
                International Catholic Congress of Anglicans Seal
              </p>
            </div>
            <div className="small-9">
              <div className="primaryRoman primaryRoman-5 px-8">
                <p className="py-4">
                  The International Catholic Congress of Anglicans is a
                  venerable entity, indeed. She stands in company with the
                  Oxford Movement, Anglo-Catholic Congresses, The Episcopal
                  Synod of America, Forward In Faith, and other movements of
                  Anglicans looking to recover the beauty and truth of Catholic
                  Faith and Tradition. The seal is set in a traditional mandorla
                  frame with the ICCA name--the mandorla being common to
                  ecclesial heraldry. This is set off with four red crosses
                  indicative of the Gospel of Our Lord Jesus Christ being taken
                  to the four corners of the earth. In the middle of a field of
                  heavenly blue are the unified red Crosses. As we are an entity
                  that seeks to breathe with “both lungs” of the Church—seeking
                  conciliarity in ecclesiology--we find the combined Latin Cross
                  of the Western Church and St. Andrew’s Saltire Cross of the
                  Eastern Church. Flanking the combined Crosses are seven golden
                  drops of Holy Oil symbolizing the Holy Spirit’s action in the
                  universally received Seven Ecumenical Councils. In the context
                  of this we find symbols of the Ancient Church in the British
                  Isles—our specific Patrimony. A fleur-de-lis reminds us of the
                  deep and abiding veneration which that Church held for the
                  Blessed Mother--with England specifically offered on numerous
                  occasions over the centuries as ‘Our Lady’s Dowry’. The shield
                  combines two famous traditional streams of antiquity that
                  reach back into the mists of time. The first stream is the
                  blue and gold saltire of St. Alban, Proto-Martyr of
                  Christianity in the British Isles. The saltire Cross itself
                  then brings in the second stream—inspired by a coat of arms
                  likely created for Glastonbury Abbey by Richard Bere, the next
                  to last Abbot of Glastonbury. His inspiration included two
                  branches of the ‘Holy Thorn’ from the Staff of St. Joseph of
                  Arimathea. Undergirding these images is the open Holy Bible
                  marked Alpha to Omega--and with an Apostolic Authority Seal of
                  Keys--reminding us of the safeguards of Sacred Scripture and
                  Holy Tradition. The year of establishment of the ICCA is noted
                  below that, reminding us that even the newest of entities must
                  be subject to those latter two things.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Crest;
