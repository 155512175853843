import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as Icons from "react-feather";
import seal from "../../resources/images/icca-seal-web.png";
import bpAckerman from "../../resources/images/bishop-keith-ackerman.png";
import bpMichael from "../../resources/images/bishop-michael-nazir-ali.png";
import styles from "./header.module.scss";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header className={styles.wrapper}>
        <div className={styles.hero}>
          <div className={styles.container}>
            <div className="small-7 pl-4 secondaryRoman text-black">
              <div className="flex">
                <a href="/">
                  <img src={seal} alt="icca-logo" className="w-12" />
                </a>
                <p className="secondaryBold-10 mt-6">
                  International Catholic Congress of Anglicans
                  <br />
                  <small className="secondaryRoman-10 italic">
                    An Orthodox Body of Anglican Christians
                  </small>
                </p>
              </div>
            </div>
            <div className="small-5 mt-4">
              <div className="flex mx-2">
                <a href="/icca-statement" className="btn btn-icca ml-auto">
                  <Icons.MessageSquare className="inline-block mr-4" />
                  ICCA Statement
                </a>
                <a href="/crest" className="btn btn-icca ml-6">
                  <Icons.Shield className="inline-block mr-4" />
                  Crest
                </a>
                <a href="/login" className="btn btn-icca ml-6">
                  <Icons.Lock className="inline-block mr-4" />
                  Login
                </a>
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <div className="small-6 my-6 py-2">
              <img
                className="rounded-t-full shadow-xl mx-auto mb-2 border border-black"
                src={bpAckerman}
                alt="bishop-ackerman"
              />
              <h4 className="secondaryRoman secondaryRoman-20 uppercase text-center text-black">
                Bishop Keith Ackerman, SSC
              </h4>
            </div>
            <div className="small-6 my-6 py-2">
              <img
                className="rounded-t-full shadow-xl mx-auto mb-2 border border-black"
                src={bpMichael}
                alt="bishop-michael"
              />
              <h4 className="secondaryRoman secondaryRoman-20 uppercase text-center text-black">
                Bishop Michael Nazir-Ali
              </h4>
            </div>
          </div>
        </div>
        <div className={styles.nav}>
          <nav className={styles.navbar}>
            <div
              className={cx(
                styles.navlink,
                this.props.active === "about" && styles.active
              )}
            >
              <a href="/about">About</a>
            </div>
            <div
              className={cx(
                styles.navlink,
                this.props.active === "videos" && styles.active
              )}
            >
              <a href="/videos">Videos</a>
            </div>
            <div
              className={cx(
                styles.navlink,
                this.props.active === "education" && styles.active
              )}
            >
              <a href="/education">Education</a>
            </div>
            <div
              className={cx(
                styles.navlink,
                this.props.active === "religious-orders" && styles.active
              )}
            >
              <a href="/religious-orders">Religious Orders</a>
            </div>
            <div
              className={cx(
                styles.navlink,
                this.props.active === "devotional-societies" && styles.active
              )}
            >
              <a href="/devotional-societies">Devotional Societies</a>
            </div>
            <div
              className={cx(
                styles.navlink,
                this.props.active === "womens-societies" && styles.active
              )}
            >
              <a href="/womens-societies">Women&apos;s Societies</a>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  active: PropTypes.string
};

export default Header;
