import React from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import styles from "./main.module.scss";
import { ReactComponent as Divider } from "../../resources/images/divider.svg";

const Congress2015 = () => {
  const embedUrls = [
    "https://www.youtube.com/embed/G2-sAZE1ruk",
    "https://www.youtube.com/embed/CwzY47t2byI",
    "https://www.youtube.com/embed/bq0icT5k_gw",
    "https://www.youtube.com/embed/24O5tUCkRLk",
    "https://www.youtube.com/embed/_nTsqR5Cf98",
    "https://www.youtube.com/embed/vKw9qmQBCoU",
    "https://www.youtube.com/embed/PZgpaHfeeOw",
    "https://www.youtube.com/embed/KCdiR49lpzw",
    "https://www.youtube.com/embed/Dwdn4W7Bx6A",
    "https://www.youtube.com/embed/oYhBbrl1NVI",
    "https://www.youtube.com/embed/2O7-0ZwWMwU"
  ];

  return (
    <div className={styles.wrapper}>
      <Header active="videos" />
      <div className="small-12 py-6">
        <h1 className="text-3xl text-center text-gray-dark mt-4 mb-8">
          2015 Congress Videos
        </h1>
        <Divider className="w-3/4 mx-auto mb-8" />
        <div className="row">
          <div className="small-12 m-6 p-2 primaryRoman primaryRoman-5">
            Please view our videos from the 2015 International Catholic Congress
            of Anglicans meeting.
          </div>
          {embedUrls.map((v, i) => {
            return (
              <div key={i} className="small-12 medium-6 my-4">
                <iframe
                  className="m-3 mx-auto"
                  src={v}
                  width="600"
                  height="400"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Congress2015;
