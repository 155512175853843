import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";

import { ReactComponent as Divider } from "../../resources/images/divider.svg";
import styles from "./main.module.scss";

class Main extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <Header />
        <div className="small-12 py-6 mb-4">
          <h1 className="text-3xl text-center text-gray-dark mt-4 mb-8">
            Video Embed
          </h1>
          <Divider className="w-3/4 mx-auto mb-8" />
        </div>
        <div className="small-12 py-6 my-4">
          <h4 className="text-center text-lg mb-4">Found out what ICCA is up to</h4>
          <iframe
            width="600"
            height="400"
            className="mx-auto"
            src="https://www.youtube.com/embed/Rqw-B9TVOmE"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Main;
