import React, { Component } from "react";
import { ReactComponent as Logo } from "../../resources/images/icca-seal.svg";
import styles from "./footer.module.scss";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.year = new Date().getFullYear();
  }

  render() {
    return (
      <footer className={styles.wrapper}>
        <div className={styles.container}>
          <Logo width={150} height={150} className={styles.logo} />
          <div className="mb-4">
            <p>INTERNATIONAL CONGRESS OF ANGLICANS</p>
            <p>4201 Mitchell Boulevard, Fort Worth, Texas 76119, United States</p>
            <p><a href="tel:8174715357">(817) 471-5357</a></p>
          </div>
          <div className="mt-4">
            Copyright &copy; {this.year} International Catholic Congress of
            Anglicans - All Rights Reserved.
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
