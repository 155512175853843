import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmail from "isemail";
import styles from "./login.module.scss";
import { ReactComponent as Seal } from "../../resources/images/icca-seal.svg";
import { ReactComponent as Loader } from "../../resources/icons/loader.svg";

const ALERT = {
  default: "There was an issue retrieving the account."
};

const ERROR = {
  email: "Please enter a valid email address.",
  pass: "Please enter a valid password."
};

const Field = props => {
  const type = props.name === "email" ? "text" : "password";

  return (
    <div className={styles.formChild}>
      <label className="ml-1">
        {props.name === "email" ? "Email Address" : "Password"}
      </label>
      <input
        type={type}
        className={props.error ? styles.inputFail : styles.inputBase}
        {...props}
      />
      {props.error && <span className={styles.errorShow}>{props.error}</span>}
    </div>
  );
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: "",
      form: {
        email: { value: "" },
        pass: { value: "" }
      },
      isSubmitted: false
    };
  }

  iccaLogin = () => {
    this.setFormSubmitted(true);

    setTimeout(() => {
      this.setFormSubmitted(false);
      window.location.href = "/admin";
    }, 2000);

    // const { fetched } = this.getContent("courier-login", {
    //   payload: {
    //     user: {
    //       known: false
    //     },
    //     email: this.state.form.email.value,
    //     password: this.state.form.password.value
    //   }
    // });
    //
    // fetched
    //   .then(res => {
    //     if (typeof res.status === "undefined") {
    //       this.setAlert(ALERT.default);
    //       return;
    //     }
    //
    //     // Manually setting the cookie, because *this is fine*
    //     if (res.status === "success") {
    //       if (cookie.load("DMN-P")) {
    //         cookie.remove("DMN-P");
    //       }
    //
    //       const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    //       document.cookie = "DMN-P=" + res.dmnp + "; expires=" + expires + "; path=/; domain=;";
    //
    //       window.location.reload();
    //       return;
    //     }
    //
    //     this.setAlert(
    //       "The email/password provided does not match our records."
    //     );
    //   })
    //   .catch(() => {
    //     this.setAlert(ALERT.default);
    //   })
    //   .finally(() => {
    //     this.setFormSubmitted(false);
    //   });
  };

  isValidForm = () => {
    return this.state.form.email.valid && this.state.form.pass.valid;
  };

  setAlert = message => {
    this.setState({
      alert: message
    });
  };

  setFormSubmitted = status => {
    this.setState({
      isSubmitted: status
    });
  };

  handleValidField = e => {
    const { name, value } = e.target;
    let valid = false;

    switch (name) {
      case "email":
        valid = isEmail.validate(value);
        break;
      case "pass":
        valid = value.length > 4;
        break;
    }

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: {
          valid: valid,
          value: value
        }
      }
    }));
  };

  handleLogin = e => {
    e.preventDefault();

    if (this.state.alert.length > 0) {
      this.setAlert("");
    }

    if (this.isValidForm()) {
      this.iccaLogin();
    }
  };

  render() {
    const { isSubmitted, alert, form } = this.state;
    const buttonDisabled = !(this.isValidForm() && !isSubmitted);

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <a href="/">
            <Seal width="250" height="250" className={styles.logo} />
          </a>
          <div className={styles.formRow}>
            <form className={styles.form} onSubmit={this.handleLogin}>
              {alert.length > 0 && (
                <div className={styles.formChild}>
                  <span className={styles.alert}>{alert}</span>
                </div>
              )}
              {Object.keys(form).map(key => {
                return (
                  <Field
                    key={key.toString()}
                    name={key}
                    value={form[key].value}
                    onChange={this.handleValidField}
                    error={
                      typeof form[key].valid !== "undefined" && !form[key].valid
                        ? ERROR[key]
                        : undefined
                    }
                  />
                );
              })}
              <div className={styles.formChild}>
                <button
                  className={styles.button}
                  disabled={buttonDisabled}
                  onClick={this.handleLogin}
                >
                  {isSubmitted ? (
                    <Loader width="10%" className="m-auto p-2" />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
              <div className={styles.formChild}>
                <div className="text-center">
                  <span className="italic">Member of ICCA?</span>
                  <a
                    className="hover:text-blue-icca ml-2 mb-2 font-bold"
                    href="#"
                  >
                    Register
                  </a>
                </div>
                <div className="text-center">
                  <a
                    className="hover:text-blue-icca ml-auto mb-2 font-bold"
                    href="#"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Field.propTypes = {
  name: PropTypes.oneOf(["email", "password"]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default Login;
