/**
 * @summary RD Sutherland React Application.
 * @module 404
 * @description Landing page for Status Code 404
 */
import React from "react";
import Panda from "../../resources/images/panda-logo.png";
import styles from "./error.module.scss";

function NotFound() {
  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        alt="Panda Logo"
        src={Panda}
        width="90"
        height="90"
      />
      <h1 className={styles.header}>404</h1>
      <p className={styles.content}>
        Sorry, the page you were trying to view does not exist.
      </p>
    </div>
  );
}

export default NotFound;
