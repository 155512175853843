import React, { Component } from "react";
import styles from "./admin.module.scss";
import { ReactComponent as Seal } from "../../resources/images/icca-seal.svg";

class Admin extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className="small-2 py-8 border-r border-black bg-blue-icca">
          <div className="border-b border-black">
            <Seal width={200} height={200} className="mx-auto mb-6" />
          </div>
          <div className="relative secondaryRoman secondaryRoman-30">
            <ul>
              <li className="hover:text-gray-dark border-b border-black">
                <div className="px-6 py-4">Home</div>
                <div className={styles.caret} />
              </li>
              <li className="hover:text-gray-dark border-b border-black">
                <div className="px-6 py-4">Videos</div>
              </li>
              <li className="hover:text-gray-dark border-b border-black">
                <div className="px-6 py-4">Users</div>
              </li>
              <li className="hover:text-gray-dark border-b border-black">
                <div className="px-6 py-4">Education Links</div>
              </li>
              <li className="hover:text-gray-dark border-b border-black">
                <div className="px-6 py-4">Devotional Societies</div>
              </li>
              <li className="hover:text-gray-dark border-b border-black">
                <div className="px-6 py-4">Women&apos;s Societies</div>
              </li>
              <li className="hover:text-gray-dark border-b border-black">
                <div className="px-6 py-4">Sign Out</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="small-9 small-offset-1 pt-12">
          <div className="m-9 pt-9">
            <h1 className="text-3xl pb-4 border-b-2 border-black">
              Admin Landing Page
            </h1>
            <div className="my-6">
              This page is currently under maintenance while requirements are
              being gathered so for now this will hold the kitchen sink.
            </div>
            <div className="my-6">
              <h4 className="text-xl pb-2 border-b border-black">
                Embedded Video from YouTube
              </h4>
              <div className="py-6 my-4">
                <iframe
                  width="600"
                  height="400"
                  src="https://www.youtube.com/embed/Rqw-B9TVOmE"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
            <form className="my-6">
              <h4 className="text-xl pb-2 border-b border-black">
                Kitchen Sink
              </h4>
              <div className="my-6">
                <div>
                  <label htmlFor="test-1">Enter your video URL</label>
                </div>
                <div>
                  <input
                    name="test-1"
                    type="text"
                    className="w-2/3 border mt-3 p-3 rounded-sm"
                  />
                </div>
              </div>
              <div className="my-6">
                <div>
                  <label htmlFor="test-2">Enter your Education links</label>
                </div>
                <div>
                  <input
                    name="test-2"
                    type="text"
                    className="w-2/3 border mt-3 p-3 rounded-sm"
                  />
                </div>
              </div>
              <div className="my-6">
                <div>
                  <label htmlFor="dropdown">Dropdown Field</label>
                </div>
                <div>
                  <select name="dropdown" className="w-1/3 mt-3 p-3 rounded-sm">
                    <option value="1">This is first item</option>
                    <option value="2">This is second item</option>
                    <option value="3">This is three item</option>
                    <option value="4">This is fourth item</option>
                    <option value="5">This is fifth item</option>
                  </select>
                </div>
              </div>
              <button className="btn btn-icca my-6 rounded-lg">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
