import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import styles from "./main.module.scss";

import { ReactComponent as Divider } from "../../resources/images/divider.svg";
import { ReactComponent as Seal } from "../../resources/images/icca-seal.svg";

class About extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <Header active="about" />
        <div className="small-12 py-6">
          <h1 className="text-3xl text-center text-gray-dark mt-4 mb-8">
            International Catholic Congress of Anglicans
          </h1>
          <Divider className="w-3/4 mx-auto mb-8" />
          <div className="row">
            <div className="small-3 border-r border-black">
              <Seal className="mx-auto" width="250" height="250" />
              <p className="text-center text-xs">
                International Catholic Congress of Anglicans Seal
              </p>
            </div>
            <div className="small-9">
              <div className="primaryRoman primaryRoman-5 px-8">
                <p className="py-4">
                  A call to all who seek a revival of the Oxford Movement in our
                  own day.
                </p>

                <p className="py-4">
                  By 2012 it became increasingly evident to many Anglo-Catholics
                  around the world that various endeavors to recall Anglicans to
                  the Historic Faith had failed. Divisions that formerly had not
                  existed among Catholics became a paralysis of all that had been
                  hoped for and realized at the famous Catholic Congresses in
                  England. Indeed, many Anglicans either never heard of nor
                  remembered the clarion messages of those gatherings of Catholic
                  Anglicans from around the world. Missionary endeavors by monks,
                  nuns, and friars, evangelism successes in various countries, and
                  ministering in humble settings, where others would not serve,
                  were central messages as Anglo-Catholics sought to extend the
                  Kingdom of Christ. At the heart of these endeavors was a strong
                  sense an ecclesiology that celebrated the “Faith once delivered”
                  as expressed in the Biblical, Patristic, Credal, and Conciliar
                  tenets of a Revealed Faith.
                </p>

                <p className="py-4">
                  In 2015 Anglo-Catholic leaders from around the world gathered to
                  encourage the Church that we had not forgotten our heritage, nor
                  had we forgotten the responsibility in continuing to be a
                  witness of the Historic Faith.
                </p>

                <p className="py-4">
                  Since then, we have seen even more disintegration and party
                  strife while at the same time witnessing a world where
                  Christianity is under greater attack than one could have ever
                  imagined. Therefore, the International Catholic Congress of
                  Anglicans is no longer an event, but is now an ongoing gathering
                  of Catholic Anglicans who are united by an undivided Lord. We
                  are an umbrella group of Devotional Societies, Theological
                  resources, Liturgical resources and Ministry opportunities. In
                  short - we are a living movement which celebrates a Faith that
                  has both a history and a future. Other excellent organizations
                  have specifically defined goals which they meet. However, many
                  people are seeking the Faith without knowing what may exist to
                  help them in their discovery. The International Catholic
                  Congress of Anglicans, therefore, will provide resources and
                  names of organizations that can best help the enquirer, and will
                  organize societies that will meet the needs of Catholic
                  Anglicans, without political or jurisdictional conflict. Many
                  who discover Catholic Anglicanism today have little interest in
                  the conflicts of the past - as significant as they may be - but
                  rather are seeking a Traditional, Orthodox and Catholic life.
                  Therefore, we are not “owned” by any jurisdiction, province, or
                  diocese in Anglicanism, but will provide resources to those who
                  simply wish to celebrate the Faith.
                </p>

                <p className="py-4">
                  “Blessed, praised, hallowed, worshiped and adored be our Lord
                  Jesus Christ, on His throne of Glory in Heaven, in the Most Holy
                  Sacrament of the Altar and in the hearts of His faithful
                  people.” [Please check in regularly as we add additional
                  resources and societies, and please pray that we will be a
                  servant and a service to Anglo-Catholics and those seeking to
                  learn more about the Catholic Faith ]
                </p>
                <p className="py-4">
                  The International Catholic Congress of Anglicans is a venerable
                  entity, indeed. She stands in company with the Oxford Movement,
                  Anglo-Catholic Congresses, The Episcopal Synod of America,
                  Forward in Faith, and other movements of Anglicans looking to
                  recover the beauty and truth of Catholic Faith and Tradition.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
