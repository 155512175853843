import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import { ReactComponent as Divider } from "../../resources/images/divider.svg";
import styles from "./main.module.scss";
import { ReactComponent as Seal } from "../../resources/images/icca-seal.svg";

class Stmt extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <Header />
        <div className="small-12 py-6">
          <h1 className="text-3xl text-center text-gray-dark mt-4 mb-8">
            ICCA Statement
          </h1>
          <Divider className="w-3/4 mx-auto mb-8" />
          <div className="row">
            <div className="small-3 border-r border-black">
              <Seal className="mx-auto" width="250" height="250" />
              <p className="text-center text-xs">
                International Catholic Congress of Anglicans Seal
              </p>
            </div>
            <div className="small-9 mx-auto">
              <div className="primaryRoman primaryRoman-5 px-8">
                <h4 className="text-2xl text-center font-bold py-4 mb-4">
                  STATEMENT OF THE INTERNATIONAL CATHOLIC CONGRESS OF ANGLICANS
                </h4>

                <p className="py-4">
                  Dear Brothers and Sisters of the Anglican Family, the Global
                  South, the Global Anglican Future Conference (GAFCON) movement,
                  and all the faithful seeking a conciliar Church:
                </p>

                <p className="py-4">
                  The International Catholic Congress of Anglicans, held July
                  13-17, 2015, at St. Andrew’s parish of the Diocese of Fort
                  Worth, Texas, of the Anglican Church in North America, gathered
                  to reaffirm a catholic and conciliar doctrine of the Church. The
                  Great Commission of our Lord directs the Church to make faithful
                  disciples, calling them out of the nations of the world to be
                  holy to the Lord. This statement seeks to sketch out the way
                  forward in fulfilling our Lord’s call to make faithful disciples
                  in the context of a properly conciliar church.
                </p>

                <h4 className="text-xl text-center font-bold py-4 mb-4">
                  SALVATION, CRISIS, AND THE CATHOLIC CHURCH
                </h4>

                <p className="py-4">
                  The Greek word for church, ekklesia, identifies these disciples
                  corporately as “the called One.” The Gospel of our Lord
                  therefore identifies this one holy people, the Church, as
                  integral to salvation for all, so that the Church Fathers and
                  the Reformers of the 16th century, echo the great African
                  Bishop, Saint Cyprian, who said: “outside the Catholic Church
                  there is no salvation,” and, “no one can have God as Father who
                  does not have the Church as mother.” God calls out a people,
                  rescuing them from sin and death, assuring them that they will
                  participate in Christ’s reign, the Kingdom of God. Indeed, it is
                  impossible to know the Lord, who calls out of darkness and into
                  His marvelous light, without being joined to His one, holy,
                  catholic, and apostolic Church. Through preaching, the
                  sacraments, catechesis, and spiritual formation, worshiping in
                  Spirit and in truth, the Church is able to make disciples by
                  being faithful to the Apostles’ teaching, the breaking of bread,
                  the prayers, and the fellowship.
                </p>

                <p className="py-4">
                  As the body has no life apart from the head, so the Church has
                  no life apart from Christ, whose Spirit is the Lord and Giver of
                  life. However, churches and the culture in the West are in
                  crisis. Secularism pervades both. In many places, Islam seeks to
                  replace the Church and radical Islam persecutes her.
                  Unprincipled egalitarianism eviscerates language, liturgy, life,
                  faith, and orders of a divided Christendom. A culture of death
                  is evident in abortion and euthanasia, and a tragic and
                  unnecessary sexual confusion shapes the paradigms of young and
                  old. What does the Church say? Where does she stand, and with
                  whom? A deficient and aberrant ecclesiology is not simply a
                  result of the present crisis in Church and culture, but is
                  rather a primary cause for the current crisis, and deserves the
                  attention of all catholic Christians.
                </p>

                <h4 className="text-xl text-center font-bold py-4 mb-4">
                  A HOLY SYNOD AND A CONCILIAR CHURCH
                </h4>

                <p className="py-4">
                  For the Church (the ekklesia) to act, she must know who she is:
                  what is she called out to be? The Church is called into
                  synodality—to come together, to worship, to live in communion
                  with the Holy Trinity, and to mirror the life of the Holy
                  Trinity. This implies the conciliarity of the whole people of
                  God, responsive to the Blessed Trinity, and participating in
                  God’s “heavenly synod” as the Church Catholic gathered around
                  God’s authoritative Holy Scriptures and the Apostolic Tradition.
                  In this, she is to be the Church on earth as she is in heaven.
                  Perhaps the clearest example of this is the First Ecumenical
                  Council (A.D. 325). The Bishops encircle the emperor’s throne
                  with the copy of God’s Holy Word on it, seeking the mind of
                  Christ, searching the Scriptures daily by the power of the Holy
                  Spirit. This perfectly expresses both the authority of the Word
                  of God written and the authority of the Church.
                </p>

                <p className="py-4">
                  As the “called out ones,” the Church consists of parts and
                  individuals, made into a whole. This is the meaning of the Greek
                  word “catholic” (kata holon “according to the whole”). It speaks
                  of wholeness and integrity. The people of God are to live, be,
                  and function as the whole Church Catholic of all ages in true
                  worship, living out the Gospel in apostolic doctrine and
                  communion.
                </p>

                <p className="py-4">
                  Continuity with the whole Church of heaven and earth for all
                  ages (by the expression of the Incarnation of Jesus Christ in
                  worship, witness, belief, and behavior) marks and identifies
                  this conciliar life in synodality. For Anglicans, this
                  continuity is expressed in the common confession of the Catholic
                  Creeds and Ecumenical Councils at which they were formed and
                  clarified.
                </p>

                <p className="py-4">
                  St. Vincent of Lérins describes this in the true,
                  Christ-centered, biblical, confessing, and conciliar sense when
                  he says that the Church upholds “what has been believed by all,
                  everywhere, and at all times.” This is the essence of kata
                  holon, “according to the whole.” When the Church is healthy she
                  is able to come together in the Great Tradition of
                  Eucharistic-centered worship around God’s heavenly throne that
                  touches earth. As the Church is at holy rest in God’s presence
                  in worship, it becomes a holy people following the unchangeable
                  teachings of Scripture as understood by the Church of all ages
                  and as bearing on the urgent issues facing the world today.
                  Worship as communion with the One, Holy, Catholic, and Apostolic
                  Church of all ages then erupts into the world with one voice,
                  bearing witness to the Good News of Jesus Christ’s glorious
                  Gospel.
                </p>

                <p className="py-4">
                  However, when the Church drifts from historic faith, order, and
                  morals, the opposite is true. Indeed, is this not what has
                  happened in the Anglican Communion? There is an inability even
                  to gather the historic Lambeth Conference due to this
                  brokenness. Sinfulness has impeded the ability to convene in
                  Holy Synod. The time has come for faithful Anglicans to reclaim
                  the apostolic and Scriptural catholicity, conciliarity, and
                  will, and to come together as a globally obedient witness in
                  Holy Synod, where bishops, clergy, religious and laity can meet
                  together to consult and decide important matters, with each
                  exercising the role proper to them.
                </p>

                <p className="py-4">
                  In a Conciliar Church, bishops hold a place of primacy as
                  servants of the servants of God in succession from the Apostles,
                  who were consecrated by Christ Himself to lead the Church into
                  the Truth of the Holy Scriptures by the power of the Holy
                  Spirit. “Where the bishop is, there is the Church,” and
                  “wherever the bishop shall appear, there let the multitude also
                  be” (Saint Ignatius). At the Council of Jerusalem (Acts 15) the
                  Apostles, in consultation with the presbyters and through prayer
                  in the power of the Holy Spirit, resolve a great doctrinal and
                  practical problem through synodal action. The whole Church,
                  clergy and laity, decide how the decision is to be communicated
                  to churches and Christians around the world. Thus bishops,
                  clergy, and laity all participate in the Church’s synodality,
                  which is effected through the gifts and work of all.
                </p>

                <p className="py-4">
                  Mutual synodality, however, does not allow the Church “to ordain
                  any thing that is contrary to God’s Word written, neither may it
                  so expound one place of Scripture, that it be repugnant to
                  another” (Articles of Religion, XX). The ancient Church Fathers
                  and Councils considered apostolic and biblical order, faith, and
                  morals by definition to be unchangeable. Thus, when the people
                  of God gather in synod, they do so in order to receive, discern
                  and follow “the Faith once for all delivered to the saints”
                  (Jude 3), in communion with the Lord Jesus Christ. Such Councils
                  find the mind of Christ that has been and always will be. The
                  realized goal of conciliarity is that the Church speak in true,
                  orthodox unity to the world with the mind of Christ. As Jesus
                  prayed just before entering the Garden of Gethsemane, this
                  oneness that He has with the Father, and seeks to have with His
                  Church, brings true belief, obedience, mission, and spiritual
                  awakening to the world (John 17).
                </p>

                <h4 className="text-xl text-center font-bold py-4 mb-4">
                  A CATHOLIC CONGRESS FOR AN ANGLICAN COMMUNION
                </h4>

                <p className="py-4">
                  Thus, the International Catholic Congress of Anglicans met to
                  address and to model a global, realigned, and fully orthodox
                  doctrine of the Church. This Congress is committed to walk in
                  conciliarity with all Christians who embrace the Catholic
                  Faith—and who allow the Faith to embrace them. A conciliar model
                  of the Church is essential to the one, holy, catholic, and
                  apostolic Church. The ancient sees of Rome, Constantinople,
                  Alexandria, Antioch and Jerusalem, and the faithful in communion
                  with them, along with Anglicans, Lutherans, and various
                  expressions of Protestantism, each have God-given charisms to be
                  given and received by all—uniting them in ultimate synodality
                  for the discipleship of all the nations of the world to Jesus.
                </p>

                <p className="py-4">
                  Only an Apostolic and conciliar Church can properly allow for
                  such giving and receiving of gifts for the people of God and for
                  the salvation of the world. Indeed, no one part of the Church
                  can stand firm against the world, the flesh and the devil
                  without the other parts. Because of her core ecclesial
                  difficulties, the Church has insufficiently addressed other
                  causes of further demise both within culture and the Church.
                  There are assaults from without such as virile secularism,
                  militant Islamic persecution, sexual confusion, and the
                  redefinition of matrimony from God’s created order upheld by
                  Christ as a lifelong sacramental union between one man and one
                  woman (Genesis 2:24; Matthew 19:4-6; Mark 10:6-9). From within
                  there are departures from a Biblical, Catholic faith and order,
                  heresy, liturgical chaos, and failure to call for repentance
                  from sin.
                </p>

                <p className="py-4">
                  These subsidiary crises, allowed to proliferate through
                  ecclesial lapses, have further fragmented Anglicans globally.
                  Some of the faithful have hoped for the best in the church homes
                  of their youth, others have formed the “Continuing Churches,” or
                  have maintained the Faith in particular jurisdictions. Primates,
                  bishops, clergy, and laity in each of these have struggled
                  valiantly to maintain the historic Church, but the fragmentation
                  continued, and distance between the faithful increased.
                </p>

                <p className="py-4">
                  God has, however, been moving among Anglicans in an
                  extraordinary way; recent years have seen significant
                  realignment emanating, for example, from GAFCON and the Global
                  South. Yet only with a healthy conciliar ecclesiology will there
                  be movement toward one another in true unity. This Congress
                  recognizes that a proper doctrine of the Church is critical,
                  requiring the attention of all faithful Anglicans.
                </p>

                <p className="py-4">
                  Now therefore, to fulfill the Great Commission—and to realize
                  further ecumenical relationships within the one, holy, catholic,
                  and apostolic Church—true unity must surpass mere federations
                  and coalitions. This International Congress invites all
                  Anglicans throughout the world (a) to a reexamination of the
                  doctrine of the Church and (b) to a further consideration of
                  areas of continuing ecclesial contention, for instance, the
                  ordination of women, deemed by some to be a first order issue.
                  This is necessary so that there may be a revival of Catholic
                  Faith and Order, and a return to a biblical, credal, and
                  conciliar fidelity. Only through honest discussion, ongoing
                  prayer, and ultimate agreement will faithful Anglicans discern
                  fully what God is doing in the great realignment taking place
                  globally. This International Congress prays also that in God’s
                  good providence there will be a truly Ecumenical Council of the
                  whole Church to address contentious issues facing Christians and
                  churches and to strengthen the faith of the Church.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Stmt;
