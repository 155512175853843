import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import styles from "./main.module.scss";
import { ReactComponent as Divider } from "../../resources/images/divider.svg";
import { ReactComponent as Seal } from "../../resources/images/icca-seal.svg";

class DevotionalSocieties extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <Header active="devotional-societies" />
        <div className="small-12 py-6">
          <h1 className="text-3xl text-center text-gray-dark mt-4 mb-8">
            Devotional Societies
          </h1>
          <Divider className="w-3/4 mx-auto mb-8" />
          <div className="row">
            <div className="small-3 border-r border-black">
              <Seal className="mx-auto" width="250" height="250" />
              <p className="text-center text-xs">
                International Catholic Congress of Anglicans Seal
              </p>
            </div>
            <div className="small-9">
              <div className="primaryRoman primaryRoman-5 px-8">
                <p className="py-4">
                  What do they got in there? King Kong? What do they got in
                  there? King Kong? Life finds a way. Must go faster... go, go,
                  go, go, go! God creates dinosaurs. God destroys dinosaurs. God
                  creates Man. Man destroys God. Man creates Dinosaurs. God
                  creates dinosaurs. God destroys dinosaurs. God creates Man.
                  Man destroys God. Man creates Dinosaurs.
                </p>
                <p className="py-4">
                  Hey, take a look at the earthlings. Goodbye! This thing comes
                  fully loaded. AM/FM radio, reclining bucket seats, and...
                  power windows. What do they got in there? King Kong?
                  Checkmate... Must go faster... go, go, go, go, go! Yeah, but
                  your scientists were so preoccupied with whether or not they
                  could, they didn&apos;t stop to think if they should.
                </p>
                <p className="py-4">
                  I was part of something special. Must go faster... go, go, go,
                  go, go! Yeah, but John, if The Pirates of the Caribbean breaks
                  down, the pirates don’t eat the tourists. Checkmate... You
                  really think you can fly that thing? Must go faster. Forget
                  the fat lady! You&apos;re obsessed with the fat lady! Drive us
                  out of here!
                </p>
                <p className="py-4">
                  Eventually, you do plan to have dinosaurs on your dinosaur
                  tour, right? We gotta burn the rain forest, dump toxic waste,
                  pollute the air, and rip up the OZONE! &apos;Cause maybe if we
                  screw up this planet enough, they won&apos;t want it anymore!
                  Remind me to thank John for a lovely weekend.
                </p>
                <p className="py-4">
                  Is this my espresso machine? Wh-what is-h-how did you get my
                  espresso machine? So you two dig up, dig up dinosaurs? Must go
                  faster... go, go, go, go, go! Did he just throw my cat out of
                  the window? Yeah, but your scientists were so preoccupied with
                  whether or not they could, they didn&apos;t stop to think if
                  they should. A call to all who seek a revival of the Oxford
                  Movement in our own day.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default DevotionalSocieties;
