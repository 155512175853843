import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./app/components/main";
import Stmt from "./app/components/stmt";
import Crest from "./app/components/crest";
import Login from "./app/components/login";
import About from "./app/components/about";
import Congress2015 from "./app/components/congress-2015";
import Education from "./app/components/education";
import ReligiousOrders from "./app/components/religious-orders";
import DevotionalSocieties from "./app/components/devotional-societies";
import WomensSocieties from "./app/components/womens-societies";
import Admin from "./app/components/admin";
import NotFound from "./app/components/404";
import Exception from "./app/components/500";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Main} />

        <Route path="/icca-statement" component={Stmt} />
        <Route path="/crest" component={Crest} />
        <Route path="/login" component={Login} />

        <Route path="/about" component={About} />
        <Route path="/videos" component={Congress2015} />
        <Route path="/education" component={Education} />
        <Route path="/religious-orders" component={ReligiousOrders} />
        <Route path="/devotional-societies" component={DevotionalSocieties} />
        <Route path="/womens-societies" component={WomensSocieties} />

        <Route path="/admin" component={Admin} />

        <Route path="/exception" component={Exception} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<Routes />, document.getElementById("devp-app"));
